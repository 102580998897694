<template>
  <div>
    <a-form-model-item :label="$t('产品')">
      <a-select
        :value="selectedMaterialItem.id"
        :disabled="!location"
        show-search
        :filterOption="filterOption"
        allowClear
        :dropdownMatchSelectWidth="false"
        style="width: 100%"
        @change="changeMaterial"
      >
        <a-select-option v-for="item in materialItems" :key="item.id" :value="item.id" :item="item">
          {{ item.name }} | {{ item.client_name }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <!-- <a-form-model-item :label="$t('产品名称')">
      <a-input :value="selectedMaterialItem.name" disabled />
    </a-form-model-item> -->
    <a-form-model-item prop="sub_batch_out" :label="$t('批次')">
      <a-select
        :value="selectedSubBatchItem.id"
        :disabled="!location"
        show-search
        allowClear
        style="width: 100%"
        :dropdownMatchSelectWidth="false"
        @change="changeSubBatch"
      >
        <a-select-option v-for="item in subBatchItems" :key="item.id" :value="item.id" :item="item">
          {{ item.batch_number }} | {{ item.status_display }}
          <span v-if="item.production_date"> | {{ item.production_date }}</span>
          <span v-if="item.pallet"> | {{ $t("容器") }}: {{ item.pallet_number }}</span>
        </a-select-option>
      </a-select>
    </a-form-model-item>
  </div>
</template>

<script>
import { subBatchOption } from "@/api/option";

export default {
  props: ["value", "location"],
  model: { prop: "value", event: "change" },
  data() {
    return {
      dataItems: [],
      materialItems: [],
      subBatchItems: [],
      selectedMaterialItem: {},
      selectedSubBatchItem: {},
    };
  },
  methods: {
    filterOption(inputValue, option) {
      const item = option.data.attrs.item;
      if (item.number.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0) {
        return true;
      }

      if (item.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0) {
        return true;
      }

      if (item.barcode && item.barcode.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0) {
        return true;
      }

      item.number.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
      return false;
    },
    getData() {
      subBatchOption({ location: this.location, page_size: 999999 }).then((data) => {
        this.dataItems = data.results;
        const materialItems = [];
        for (let item of this.dataItems) {
          if (materialItems.findIndex((_item) => _item.id == item.material) === -1) {
            materialItems.push({
              id: item.material,
              number: item.material_number,
              name: item.material_name,
              barcode: item.material_barcode,
              client_name: item.client_name,
            });
          }
        }

        this.materialItems = materialItems;
      });
    },
    changeMaterial(value, option) {
      const subBatchItems = [];

      if (value) {
        for (let item of this.dataItems) {
          if (item.material == value) {
            subBatchItems.push(item);
          }
        }
      }

      this.selectedMaterialItem = option?.data.attrs.item || {};
      this.selectedSubBatchItem = { id: undefined };
      this.subBatchItems = subBatchItems;
    },
    changeSubBatch(value, option) {
      this.selectedSubBatchItem = option?.data.attrs.item || {};
      this.$emit("change", value);
      this.$emit("select", this.selectedSubBatchItem);
    },
    resetData() {
      this.dataItems = [];
      this.materialItems = [];
      this.subBatchItems = [];
      this.selectedMaterialItem = {};
      this.selectedSubBatchItem = {};
    },
  },
  watch: {
    location(value) {
      if (value) {
        this.getData();
      }

      this.resetData();
    },
  },
};
</script>

<style scoped></style>
